import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('USER_ITEM_SUBSCRIPTION').plugin(axiosPlugin,{template: "array"});


let updateUserItemSubscriptionAction = redux.fetchActionUpdate();
let fetchItemSubscriptionAction = redux.fetchActionGetOne();

export const TYPES = redux.types;

export const fetchUserItemSubscription = (userId) => fetchItemSubscriptionAction(`/users/${userId}/subscription-admin`);
export const updateUserItemSubscription = (userId, data = {}) => updateUserItemSubscriptionAction(`/users/${userId}/update-subscription-admin`, data);
export const resetUserItemSubscription = () => redux.actions.reset();

export const userItemSubscription = redux.reducer();