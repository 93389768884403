import React from "react";
import {config} from "../../../../config";
import {Col, Image} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SpinLoader} from "../../../components/Loader";


const ContentUser = props => {
    let {username = "Pseudo", gender = 0, src = "",_id = 0, deleteList = ()=>{console.log("Fonction non envoyer");}, openModal = ()=>{ return null;}, tachesRemoveIds = [] } = props;
    gender = config.genderList[gender];
    let loading = tachesRemoveIds.includes(_id) ? true : false;

    return (
        <Col sm={2} className={"p-0"}>
            <Col sm={12} className={"font-size-14"}>
                {username}
            </Col>
            <Col sm={12} className={"font-size-14"}>
               Genre : {gender}
            </Col>
            <Col sm={12}>
                <Image src={src} thumbnail onClick={()=>openModal()} />
            </Col>
            <Col sm={12} className={"text-center mb-3"}>
                {loading ? (<div className={"pt-2 flex justify-content-center"}><SpinLoader className={"xsmall"} /></div>) : (
                    <FontAwesomeIcon
                        icon="trash"
                        className={"cursor-pointer"}
                        onClick={()=>deleteList(_id)}
                    />
                )}

            </Col>
        </Col>
    )
}

export default ContentUser;