import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Route, Router, Switch} from "react-router-dom";
import _ from "lodash";

import {fetchUser, resetUser} from "../../../creators/user";

import MemberProfile from "./Profile";
import MemberSetting from "./Setting";
import MemberTransaction from "./Transaction";
import MemberSessions from "./Sessions";

import {PageLoader} from "../../components/Loader";
import SubNavBar from "../../components/SubNavBar";
import MemberDating from "./Dating";
import MemberAutoLogin from "./AutoLogin";
import MemberWarning from "./Warnings";
import MemberRooms from "./Rooms";
import MemberRoom from "./Room";


class Member extends React.Component {
    constructor(props) {
        super(props);
        this.currentUserId = this.props.match.params.id || false;
        this.pathPage = '/members';
        this.subNavBar = [
            {link : `${this.pathPage}/${this.currentUserId}`, label : "Profil"},
            {link : `${this.pathPage}/${this.currentUserId}/setting`, label : "Paramètres"},
            {link : `${this.pathPage}/${this.currentUserId}/dating`, label : "Rencontre"},
            {link : `${this.pathPage}/${this.currentUserId}/transaction`, label : "Transactions"},
            {link : `${this.pathPage}/${this.currentUserId}/session`, label : "Sessions"},
            {link : `${this.pathPage}/${this.currentUserId}/warning`, label : "Messages de modération"},
            {link : `${this.pathPage}/${this.currentUserId}/rooms`, label : "Rooms"}
        ];
        this.props.resetUser();
        if(this.currentUserId){
            this.props.fetchUser(this.currentUserId);
        }
    }


    componentWillUnmount() {
        this.props.resetUser();
    }

    render() {
        const isLockRole = _.get(this.props, "user.role") === "banned" && _.get(this.props, "user.banned.reason") === "Compte en cours de suppression";
        return !this.props.userInitialized || !this.props.userId ? (<PageLoader />) :(
            isLockRole ? (
                <Fragment>Compte en cours de suppression</Fragment>
                ) : (
                <Fragment>
                    <SubNavBar {...this.props} subNav={this.subNavBar}/>
                    <Router history={this.props.history}>
                        <Switch>
                            <Route path={`${this.pathPage}/:id`} exact component={MemberProfile}/>
                            <Route path={`${this.pathPage}/:id/setting`}  component={MemberSetting}/>
                            <Route path={`${this.pathPage}/:id/dating`} exact component={MemberDating}/>
                            <Route path={`${this.pathPage}/:id/transaction`} exact component={MemberTransaction}/>
                            <Route path={`${this.pathPage}/:id/session`} exact component={MemberSessions}/>
                            <Route path={`${this.pathPage}/:id/auto-login`} exact component={MemberAutoLogin}/>
                            <Route path={`${this.pathPage}/:id/warning`} exact component={MemberWarning}/>
                            <Route path={`${this.pathPage}/:id/rooms`} exact component={MemberRooms}/>
                            <Route path={`${this.pathPage}/:id/rooms/:roomId`} exact component={MemberRoom}/>
                        </Switch>
                    </Router>
                </Fragment>
                )

        )
    }
}


const mapStateToProps = (state) => {
    return {
        userInitialized: state.user.initialized,
        user : _.get(state,"user.data"),
        userId : _.get(state,"user.data._id")
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUser,
    resetUser
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Member);
