import React from "react";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    XAxis,
    YAxis,
    Tooltip, ResponsiveContainer
} from "recharts";


const RechartsCustom = props => {
    let {data = [], datakey = []} = props;
    return (
        <div style={{ width: '100%', height: 300 }}>
        <ResponsiveContainer>
        <LineChart
            data={data}
            margin={{ top: 5, right: 30, bottom: 5, left: 20 }}
        >
            <CartesianGrid vertical={false} strokeDasharray="3 3"  />
            <XAxis dataKey="date"  angle={-35} interval={0} dx={-40} dy={25} height={80} />
            <YAxis scale={"log"}  domain={[0.1, 'auto']} allowDataOverflow />
            {/*auto|linear|pow|sqrt|log|identify|time|band\point\ordinal|quantile|quantize|utcTime|sequential|threshold*/}
            <Tooltip
                wrapperStyle={{
                    borderColor: 'white',
                    boxShadow: '2px 2px 3px 0px rgb(204, 204, 204)',
                }}
                contentStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
                labelStyle={{ fontWeight: 'bold', color: '#666666' }}
            />
            <Legend />
            {datakey.map(item =>(<Line type="monotone" key={item.key} dataKey={item.key} stroke={item.stroke} dot={true} activeDot={{ r: 5 }}  />))}

            {/*{data.length >13 ? (*/}
            {/*    <Brush dataKey="date" startIndex={data.length - 15}>*/}
            {/*        <AreaChart>*/}
            {/*            <CartesianGrid />*/}
            {/*            <YAxis hide domain={['auto', 'auto']} />*/}
            {/*            <Area dataKey="date" stroke="#ff7300" fill="#ff7300" dot={false} />*/}
            {/*        </AreaChart>*/}
            {/*    </Brush>*/}
            {/*) : null}*/}

        </LineChart>
        </ResponsiveContainer>
        </div>
    )
}

export default RechartsCustom;