import {config} from '../config';

const createLink = (type , data = {}) => {
    switch(type){
        //Profile Users
        case"userProfile":
            return `${config.urlSiteVoissa}/${config.genderUrl[data.profile.gender]}/${data.slug}`;

        case"userProfileAdmin":
            return `/members/${data._id}`;
        
        case"userDating":
            return `${config.urlSiteVoissa}/${config.genderUrl[data.profile.gender]}/${data.slug}/rencontre`;
        
        case"userAlbums":
            return `${config.urlSiteVoissa}/exhibition-sexe/${data.slug}`;
        
        case"userAlbum":
            return `${config.urlSiteVoissa}/exhibition-sexe/${data.slug}/${data.album.slug}_${data.album._id}`;
        
        case"userArticles":
            return data && data.blog && data.blog.title ? `${config.urlSiteVoissa}/blog-sexe-amateur/${data.slug}/${data.blog.slug}` : `${config.urlSiteVoissa}/blog-sexe-amateur/${data.slug}`;
        
        case"userFriends":
            return `${config.urlSiteVoissa}/${config.genderUrl[data.profile.gender]}/${data.slug}/amis`;


        // Page Photos
        case"photos":
            return `${config.urlSiteVoissa}/photos-sexe`;
        
        // Page Videos
        case"videos":
            return `${config.urlSiteVoissa}/videos-sexe`;
        
        // Page MediaViewer
        case"mediaviewerPhoto":
            return `${config.urlSiteVoissa}/xxx-photos/${data.media.slug}_${data.media._id}`;
        
        case"mediaviewerVideo":
            return `${config.urlSiteVoissa}/xxx-videos/${data.media.slug}_${data.media._id}`;

        
        // Forum
        case"forum":
            return `${config.urlSiteVoissa}/forum-sex`;
        
        case"forumCategory":
            return data.categoryParent && data.categoryParent.slug ? `${config.urlSiteVoissa}/forum-sex/${data.categoryParent.slug}/${data.category.slug}` : `${config.urlSiteVoissa}/forum-sex/${data.category.slug}`;
    
        case"forumTopic":
            let topicUrl =  data.categoryParent && data.categoryParent.slug ? `${config.urlSiteVoissa}/forum-sex/${data.categoryParent.slug}/${data.category.slug}/${data.topic.slug}_${data.topic._id}` : `${config.urlSiteVoissa}/forum-sex/${data.category.slug}/${data.topic.slug}_${data.topic._id}`;
            topicUrl = data.topic.page >1 ? `${topicUrl}/${data.topic.page}` : topicUrl;
            return data.topic.ancre ? `${topicUrl}/${data.topic.ancre}` : topicUrl;

    
        // Articles
        case"articles":
            return `${config.urlSiteVoissa}/blog-sexe-amateur`;
        
        case"article":
            return `${config.urlSiteVoissa}/blog-sexe-amateur/${data.slug}/${data.article.slug}_${data.article._id}`;
        
        // Stories
        case"stories":
            return `${config.urlSiteVoissa}/histoire-de-sexe`;

        case"storiesRules":
            return `${config.urlSiteVoissa}/histoire-de-sexe/regles`;
        
        case"story":
            return `${config.urlSiteVoissa}/histoire-de-sexe/${data.story.slug}_${data.story._id}`;

        case"home":
            return `${config.urlSiteVoissa}`;


        case"ediFacette":
            return `/categorizations/${data.media._id}`;

        case"announcementMessages":
            return `/contents/announcement-messages`;
        
        default : return "";

    }
};


// createLink("charter");
export default createLink;
