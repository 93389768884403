import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Col, Modal, Row} from "react-bootstrap";
import FormProfile from "../../formulaires/member.profile";
import FormProfilePassword from "../../formulaires/member.password";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UploaderAC from "../../components/upload/UploadAC";
import {updateUser} from "../../../creators/user";
import FormProfileBan from "../../formulaires/member.bannir";
import {SpinLoader} from "../../components/Loader";
import FormProfileDeleteAccount from "../../formulaires/member.deleteAccount";
import moment from "moment";
import _ from "lodash";
import {deleteUserContents} from "../../../creators/user.removeContents";
import FormProfilePemium from "../../formulaires/member.premium";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadAvatar : false,
            uploadPhoto : false,
            uploadCover : false,
            toggleAlert : false,
            toggleAlertDeleteContent : false,
            currentField : ""
        }
        this.handleDelete = this.handleDelete.bind(this);
        this.toggleDelete = this.toggleDelete.bind(this);
        this.deleteContent = this.deleteContent.bind(this);
    }

    toggleDelete(field = "") {
        this.setState({toggleAlert : true, currentField : field})
        // this.props.updateUser(this.props.user._id,{profile : {[field] : ""}});
    }


    handleDelete() {
        let field = this.state.currentField;
        this.props.updateUser(this.props.user._id,{profile : {[field] : ""}});
        this.setState({toggleAlert : false, currentField : ""});
    }
    deleteContent() {
        this.props.deleteUserContents(this.props.user._id);
        this.setState({toggleAlertDeleteContent : false});
    }

    Unbanning(){
        if(!this.props.userLoading) {
            let user = this.props.user;
            delete user.__v;
            user = {
                ...user,
                isSpammer : 2,
                isRisk : false,
                role : user.banned.roleBefore || "emailUnconfirmed",
                banned : {
                    reason : "",
                    roleBefore : user.role,
                    date : moment()
                }
            }
            this.props.updateUser(this.props.user._id, user);
        }
    }


    render() {

        return (
            <div className={"mb-5"}>
            <Row className="justify-content-md-center">
                <Col xs={6}>
                    <FormProfile {...this.props} />
                </Col>
                <Col xs={6}>
                    <Row className="justify-content-md-center">
                        <Col xs={6}>
                            <Button size="sm" className={"mb-2"} variant="outline-info" onClick={()=>this.setState({uploadAvatar : true})}>
                                Upload avatar
                            </Button>
                            {this.props.user && this.props.user.profile && this.props.user.profile.avatar && (<Button size="sm" className={"mb-2 ml-5"} variant="danger" onClick={()=>this.toggleDelete("avatarId")}>
                                X
                            </Button>)}
                            {this.props.user && this.props.user.profile && this.props.user.profile.avatar && (<Col xs={6}><img src={`${this.props.user.profile.avatar}?width=150&height=150`} alt={"Avatar"} width={150} /></Col> ) }
                        </Col>

                        <Col xs={6}>
                            <Button size="sm" className={"mb-2"} variant="outline-info" onClick={()=>this.setState({uploadPhoto : true})}>
                                Upload Certification
                            </Button>
                            {this.props.user && this.props.user.profile && this.props.user.profile.photoCertified && (<Button size="sm" className={"mb-2 ml-5"} variant="danger" onClick={()=>this.toggleDelete("photoCertifiedId")}>
                                X
                            </Button>)}
                            {this.props.user && this.props.user.profile && this.props.user.profile.photoCertified && (<Col xs={6}><a href={this.props.user.profile.photoCertified} target={"_blank"} ><img src={`${this.props.user.profile.photoCertified}?width=150&height=150`} alt={"photoCertified"} width={150} /></a></Col> ) }
                        </Col>

                        <Modal centered backdrop={false} show={this.state.uploadAvatar} onHide={()=>this.setState({uploadAvatar : false})}>
                            <Modal.Header closeButton>
                                <Modal.Title>Upload Avatar</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <UploaderAC type={"avatar"}
                                            uploadPath={`/users/${this.props.user._id}/upload-avatar`}
                                            userId={this.props.user._id}
                                            handleClose={() => this.setState({uploadAvatar: false})}/>
                            </Modal.Body>
                            <Modal.Footer>
                            </Modal.Footer>
                        </Modal>

                        <Modal centered backdrop={false} show={this.state.uploadPhoto} onHide={()=>this.setState({uploadPhoto : false})}>
                            <Modal.Header closeButton>
                                <Modal.Title>Upload Certification</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <UploaderAC type={"photo"}
                                            uploadPath={`/users/${this.props.user._id}/upload-photo-cert`}
                                            userId={this.props.user._id}
                                            handleClose={() => this.setState({uploadPhoto: false})}/>
                            </Modal.Body>
                            <Modal.Footer>
                            </Modal.Footer>
                        </Modal>
                    </Row>
                    <Row className="justify-content-md-center mt-4">
                        <Col xs={12}>
                            <Button size="sm" className={"mb-2"} variant="outline-info" onClick={()=>this.setState({uploadCover : true})}>
                                Upload Cover
                            </Button>
                            {this.props.user && this.props.user.profile && this.props.user.profile.cover && (<Button size="sm" className={"mb-2 ml-5"} variant="danger" onClick={()=>this.toggleDelete("coverId")}>
                                X
                            </Button>)}
                            {this.props.user && this.props.user.profile && this.props.user.profile.cover && (<Col xs={12}><img src={`${this.props.user.profile.cover}?width=150&height=100`} alt={"cover"} width={150} /></Col> ) }
                        </Col>
                        <Modal centered backdrop={false} show={this.state.uploadCover} onHide={()=>this.setState({uploadCover : false})}>
                            <Modal.Header closeButton>
                                <Modal.Title>Upload Cover</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <UploaderAC type={"cover"}
                                            uploadPath={`/users/${this.props.user._id}/upload-cover`}
                                            userId={this.props.user._id}
                                            handleClose={() => this.setState({uploadCover: false})}/>
                            </Modal.Body>
                            <Modal.Footer>
                            </Modal.Footer>
                        </Modal>
                        <Col xs={12} className={"mt-4"}>
                            <h4 className={"w-100"}>Premium</h4>
                            <FormProfilePemium user={this.props.user}/>
                        </Col>
                    </Row>
                </Col>
                <Modal centered backdrop={false} show={this.state.toggleAlert} onHide={()=>this.setState({toggleAlert : false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>SUPPRESSION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Voulez-vous vraiment supprime ce fichier ?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.setState({toggleAlert : false, currentField : ""})}>
                            Non
                        </Button>
                        <Button variant="danger" onClick={()=>this.handleDelete()}>
                            Oui
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal centered backdrop={false} show={this.state.toggleAlertDeleteContent} onHide={()=>this.setState({toggleAlerttoggleAlertDeleteContent : false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>SUPPRESSION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className={"text-danger"}>Cette action est exécuter en tache merci d'etre patient.</p>
                        <p>Voulez-vous vraiment supprime le contenu ?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.setState({toggleAlertDeleteContent : false})}>
                            Non
                        </Button>
                        <Button variant="danger" onClick={()=>this.deleteContent()}>
                            Oui
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Row>
            <Row className={"mt-5 p-2"}>
                <h4 className={"w-100"}>Changer le mot de passe</h4>
                <FormProfilePassword />
            </Row>
            <Row className={"mt-5 p-2"}>
                <h4 className={"w-100"}>Action</h4>
                <div className={"w-100 pb-2"}>
                    <Button variant="warning" onClick={()=>{this.setState({toggleAlertDeleteContent : true})}}>
                        <FontAwesomeIcon
                            icon="trash"
                        /> Supprimer le contenu de l'utilisateur
                    </Button>
                </div>
                {_.get(this.props,"user.isAbo") ? (
                    <Col xs={12} ><p className={"text-danger"}>Impossible d'effacer ce compte, Ce membre a un abonnement active </p></Col>
                ) : (
                    <Col xs={2} >
                        <FormProfileDeleteAccount />
                    </Col>
                )}
                <Col xs={8} >
                    {this.props.user.role !=="banned" ? (<FormProfileBan deleteContent={()=>this.deleteContent()} />) : (
                        <Button variant="warning" onClick={()=>{this.Unbanning()}}>
                            <FontAwesomeIcon
                                icon="ban"
                            /> Débannir {this.props.userLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                        </Button>
                    )}
                    {_.get(this.props, "user.role") === "banned" && _.get(this.props, "user.banned.reason") ? (
                        <Row>
                            <Col xs={12}>
                                Date : {moment(this.props.user.banned.date).format("MM-DD-YYYY HH:mm:ss")}
                            </Col>
                            <Col xs={12}>
                                Raison : {this.props.user.banned.reason}
                            </Col>
                        </Row>
                    ) : null}
                </Col>
            </Row>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user.data
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateUser,
    deleteUserContents
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
