import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Aqp from '../../../libs/mk-react-aqp';
import {fetchModerationContents, resetModerationContents} from "../../../creators/moderationContents";
import {refusedModerationContent, acceptedModerationContent} from "../../../creators/moderationContent";
import {PageLoader, SpinLoader, LoadingBar} from "../../components/Loader";
import FilterGender from "../../components/FilterGender";
import ContentUser from "./components/ContentUser";
import {Button, Modal, Row} from "react-bootstrap";
import createLink from "../../../libs/createLink";


let mapping = {
    "sort" : "trie",
    "owner.profile.gender" : "genre"
};

class ModerationsPhotoCertified extends React.Component {
    constructor(props) {
        super(props);
        this.type = "photo-certified";
        this.filter = {
            limit: 18,
            page: 1,
            sort: "createdAt",
        };
        this.aqp = new Aqp(mapping, {
            blacklist: ['limit', 'page'],
            currentLink: "/moderations/certifications",
            filterDefault: this.filter
        });

        this.state = {
            filter: {...this.filter, ...this.aqp.query, page: this.props.match.params.page || 1},
            tachesRemoveIds : [],
            modalImg : ""
        };
        this.props.resetModerationContents();
        this.fetchModerationContents(this.state.filter);

        this.handleGender = this.handleGender.bind(this);
        this.submitAccepted = this.submitAccepted.bind(this);
        this.submitRefused = this.submitRefused.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    UNSAFE_componentWillMount(){
        this.props.resetModerationContents();
    }

    fetchModerationContents(filter){
        this.props.fetchModerationContents(this.type, this.aqp.set(filter));
        this.setState({filter})
    }

    handleGender(gender){
        let filter = {...this.state.filter, "owner.profile.gender" : gender, page : 1};
        if(filter["owner.profile.gender"] === ""){
            delete filter.gender;
        }
        else{
            filter["owner.profile.gender"] = parseInt(filter["owner.profile.gender"])
        }
        this.fetchModerationContents(filter);
    }

    submitRefused(id){
        this.props.refusedModerationContent(this.type, id);
        let tachesRemoveIds = [...this.state.tachesRemoveIds,id];
        this.setState({tachesRemoveIds});
    }
    submitAccepted(){
        let ids = this.props.moderationContents.map(item => item._id);
        this.props.acceptedModerationContent(this.type, {ids});
    }

    openModal(link = ""){
        this.setState({modalImg : link});
    }

    render() {
// console.log(this.props.moderationContentsInitialized);
        return !this.props.moderationContentsInitialized ? (<PageLoader />) :(
            <Fragment>
                <FilterGender value={this.state.filter["owner.profile.gender"]} onChange={(gender)=>{this.handleGender(gender)}}/>
                <Row>
                    {this.props.moderationContentsLoading ? (<LoadingBar />) : (
                        this.props.moderationContents.map((item) =>{
                            let content = item.parentId;
                            return <ContentUser tachesRemoveIds={this.state.tachesRemoveIds} key={item._id} gender={content.profile && content.profile.gender} src={content.profile.photoCertified ? `${content.profile.photoCertified}?width=200&height=200` : ""} openModal={()=>this.openModal(content.profile.photoCertified ? content.profile.photoCertified : "")} username={<a href={createLink("userProfile", content)} target={"new"}>{content.username}</a>} deleteList={this.submitRefused} _id={item._id} />
                        })
                    )}
                </Row>
                <Button variant="primary" className={"btn-loading"} onClick={()=>this.submitAccepted()}>
                    Valider {this.props.moderationContentLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                </Button>
                {this.state.modalImg !=="" ? (
                    <Modal className={"modal-big-thumb"} centered backdrop={false} show={this.state.modalImg !== "" ? true : false} onHide={()=>this.setState({modalImg : ""})}>
                        <Modal.Header closeButton>
                            <Modal.Title>Photo Certifier</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <img alt={"img"} src={this.state.modalImg} />
                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                    </Modal>
                ) : null}
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        moderationContents : state.moderationContents.data,
        moderationContentsTotal : state.moderationContents.total,
        moderationContentsLoading : state.moderationContents.loading,
        moderationContentsInitialized : state.moderationContents.initialized,

        // moderationContent : state.moderationContents.data,
        // moderationContentLoading : state.moderationContent.loading,
        // moderationContentInitialized : state.moderationContent.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchModerationContents,
    refusedModerationContent,
    acceptedModerationContent,
    resetModerationContents
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModerationsPhotoCertified);
